import { CAMPAIGN_TYPES } from '../constants/campaign';

export default {
  computed: {
    campaignType() {
      const campaignTypeMap = {
        ongoing_campaigns: CAMPAIGN_TYPES.ONGOING,
        one_off: CAMPAIGN_TYPES.ONE_OFF,
      };
      return campaignTypeMap[this.$route.name];
    },
    isOngoingType() {
      return this.campaignType === CAMPAIGN_TYPES.ONGOING;
    },
    isOneOffType() {
      return this.campaignType === CAMPAIGN_TYPES.ONE_OFF;
    },
  },
  methods: {
    appendNewPeerAudience() {
      this.selectedAudience.push([]);
      this.selectedAudienceOperator.push('and');
    },
    removePeerAudience(idx) {
      this.selectedAudience.splice(idx, 1);
      this.selectedAudienceOperator.splice(idx, 1);
    }
  },
};
