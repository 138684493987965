import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import { FEATURE_FLAGS } from 'dashboard/featureFlags';

export default {
  computed: {
    ...mapGetters({
      accountLabels: 'labels/getLabels',
      teams: 'teams/getMyTeams',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    savedLabels() {
      // If conversationLabels is passed as prop, use it
      if (this.conversationLabels)
        return this.conversationLabels.split(',').map(item => item.trim());
      // Otherwise, get labels from store
      return this.$store.getters['conversationLabels/getConversationLabels'](
        this.conversationId
      );
    },
    // TODO - Get rid of this from the mixin
    activeLabels() {
      return this.allowedLabels.filter(({ title }) =>
        this.savedLabels.includes(title)
      );
    },
    inactiveLabels() {
      return this.allowedLabels.filter(
        ({ title }) => !this.savedLabels.includes(title)
      );
    },
    allowedLabels() {
      if (this.enableLabelByTeam && !this.isAdmin) {
        let allowedLabels = [];
        let allowedTeamIds = this.teams.map(item => item.id);
        for (let i=0; i < this.accountLabels.length; i++) {
          let label = this.accountLabels[i];
          let labelTeams = label?.teams || [];
          if (labelTeams.length === 0 || (labelTeams.find(item => allowedTeamIds.includes(item)))) allowedLabels.push(label);
        }
        return allowedLabels;
      }
      return this.accountLabels;
    },
    enableLabelByTeam() {
      return this.isFeatureEnabledonAccount(
          this.accountId,
          FEATURE_FLAGS.SET_LABEL_BY_TEAM
      );
    },
  },
  methods: {
    addLabelToConversation(value) {
      let activeLabelsOrigin = this.accountLabels.filter(({ title }) =>
          this.savedLabels.includes(title)
      )
      const result = activeLabelsOrigin.map(item => item.title);
      result.push(value.title);
      this.onUpdateLabels(result);
    },
    removeLabelFromConversation(value) {
      let activeLabelsOrigin = this.accountLabels.filter(({ title }) =>
          this.savedLabels.includes(title)
      )
      const result = activeLabelsOrigin
        .map(label => label.title)
        .filter(label => label !== value);
      this.onUpdateLabels(result);
    },
    async onUpdateLabels(selectedLabels) {
      this.$store.dispatch('conversationLabels/update', {
        conversationId: this.conversationId,
        labels: selectedLabels,
      });
    },
  },
};
