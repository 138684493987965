/* global axios */
import CacheEnabledApiClient from './CacheEnabledApiClient';

class Inboxes extends CacheEnabledApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'inbox';
  }

  getCampaigns(inboxId) {
    return axios.get(`${this.url}/${inboxId}/campaigns`);
  }

  deleteInboxAvatar(inboxId) {
    return axios.delete(`${this.url}/${inboxId}/avatar`);
  }

  getAgentBot(inboxId) {
    return axios.get(`${this.url}/${inboxId}/agent_bot`);
  }

  setAgentBot(inboxId, botId) {
    return axios.post(`${this.url}/${inboxId}/set_agent_bot`, {
      agent_bot: botId,
    });
  }

  importChats(inboxId, file) {
    const formData = new FormData();
    formData.append('import_file', file);
    return axios.post(`${this.url}/${inboxId}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  syncTemplate(inboxId) {
    return axios.post(`${this.url}/${inboxId}/sync_template`);
  }

  createTemplate(inboxId, data) {
    return axios.post(`${this.url}/${inboxId}/create_template`, data);
  }

  editTemplate(inboxId, data) {
    return axios.post(`${this.url}/${inboxId}/edit_template`, data);
  }
}

export default new Inboxes();
